import Vue from "vue";
import Router from "vue-router";

const Home = () => import("./pages/home/home.vue");
const Video = () => import("./pages/video/video.vue");
const Gas = () => import("./pages/gas/gas.vue");
const Warning = () => import("./pages/warning/warning.vue");
const User = () => import("./pages/user/user.vue");
const ShipInfo = () => import("./pages/ship/ship-info.vue");
const MonitorWater = () => import("./pages/monitor/monitor-water.vue");
const MonitorLaw = () => import("./pages/monitor/monitor-law.vue");
const WarningPush = () => import("./pages/warning/warning-push.vue");
const WarningThreshold = () => import("./pages/warning/warning-threshold.vue");
const VideoRecord = () => import("./pages/video/video-record.vue");
const Login = () => import("./pages/login/login.vue");

Vue.use(Router);

const vueRouter = new Router({
    routes: [
        {
            path: "/login",
            name: "Login",
            component: Login,
            meta: {
                auth: false,
            },
            children: [],
        },
        {
            path: "/",
            name: "Home",
            component: Home,
            meta: {
                auth: true,
            },
            children: [
                {
                    path: "/video",
                    name: "Video",
                    meta: {
                        auth: true,
                    },
                    component: Video,
                },
                {
                    path: "/gas",
                    name: "Gas",
                    meta: {
                        auth: true,
                    },
                    component: Gas,
                },
                {
                    path: "/warning",
                    name: "Warning",
                    meta: {
                        auth: true,
                    },
                    component: Warning,
                },
                {
                    path: "/user",
                    name: "User",
                    meta: {
                        auth: true,
                    },
                    component: User,
                },
                {
                    path: "/ship",
                    name: "ShipInfo",
                    meta: {
                        auth: true,
                    },
                    component: ShipInfo,
                },
                {
                    path: "/monitor/water",
                    name: "MonitorWater",
                    meta: {
                        auth: true,
                    },
                    component: MonitorWater,
                },
                {
                    path: "/monitor/law",
                    name: "MonitorLaw",
                    meta: {
                        auth: true,
                    },
                    component: MonitorLaw,
                },
                {
                    path: "/warning/push",
                    name: "WarningPush",
                    meta: {
                        auth: true,
                    },
                    component: WarningPush,
                },
                {
                    path: "/warning/threshold",
                    name: "WarningThreshold",
                    meta: {
                        auth: true,
                    },
                    component: WarningThreshold,
                },
                {
                    path: "/video/record",
                    name: "VideoRecord",
                    meta: {
                        auth: true,
                    },
                    component: VideoRecord,
                },
            ],
        },
    ],
});

vueRouter.beforeEach((to, from, next) => {
    // 先把登录的相关逻辑屏蔽
    let token = localStorage.getItem("token");
    if (token) {
        if (to.path == "/") {
            next("/video");
        } else {
            next();
        }
    } else {
        if (to.meta.auth) {
            next("/login");
        } else {
            next();
        }
    }
});

export default vueRouter;
