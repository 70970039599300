import Vue from 'vue'
import App from './App.vue'
import router from './router'
import iView from 'iview'
import 'iview/dist/styles/iview.css'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import dataV from '@jiaminghi/data-view'
import "amfe-flexible/index.js";

Vue.prototype.$video = videojs;
Vue.config.productionTip = false
Vue.use(iView)
Vue.use(dataV)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
