<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
// import Home from './pages/home/home.vue'

export default {
    name: 'App',
    components: {
        // Home
    }
}
</script>

<style lang="scss">
html,
body {
    margin: 0px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        height: 100%;
        background-color: #040c31;
    }
}

p,
span {
    padding: 0;
    margin: 0;
}

.can-click {
    color: #23aaf2;
    display: flex;
    justify-content: space-between;

    // &:first-child {
    //   margin-left: 0;
    // }
}
</style>
